<template>
  <el-select
    :style="{ width: width }"
    size="mini"
    v-model="storeId"
    :loading="isLoading"
    filterable
    clearable
    remote
    :remote-method="getList"
    placeholder="请输入搜索"
  >
    <el-option v-for="item in list" :label="item.capitalName" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getListAPI } from './api.js'
export default {
  name: 'SelectStore',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '220px'
    }
  },

  computed: {
    storeId: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        pageSize: 20,
        storeName: query
      }
      let res = await getListAPI(params)
      this.list = res.list
    }
  }
}
</script>

<style></style>
