import { http } from '@/http/axios.js'

export const MENU_TYPE = [
  { name: '目录', value: 1 },
  { name: '菜单', value: 2 },
  { name: '按钮', value: 3 }
]

// 同步常量
export const SYNC_ZUJI = 'ok_zuji'
export function getDictEnumListAPI(dictCode) {
  return http({
    url: '/admin/sys/dictEnum/list',
    method: 'get',
    params: { dictCode }
  }).then(res => {
    return res.map(item => {
      return {
        name: item.enumText,
        value: item.enumCode
      }
    })
  })
}
export const stockType = [
  { name: '入库', value: 1 },
  { name: '出库', value: 2 }
]
export const genderDict = [
  { name: '男', value: 1 },
  { name: '女', value: 0 }
]
export const targetSideDict = [
  { name: '外部', value: 2 },
  { name: '内部', value: 1 }
]
export const targetTypeDict = [
  { name: '小程序', value: 1 },
  { name: 'h5', value: 2 }
]
// 财务管理
export const searchTypeReport = [
  { name: '订单号', value: 'orderNo' },
  { name: '交易单号', value: 'openTradeNo' },
  { name: '客户姓名', value: 'memberName' },
  { name: '商户名称', value: 'merchantName' },
  { name: '审核人员', value: 'reviewUserName' },
]
export const searchTypeCount = [
  { name: '订单号', value: 'orderNo' },
  { name: '客户姓名', value: 'memberName' },
  { name: '商户名称', value: 'merchantName' }
]
export const amountType = [
  { name: '收入', value: 1 },
  { name: '支出', value: 2 }
]
// 审核管理
// 审核列表
export const searchTypeReview = [
  { name: '订单号', value: 'orderNo' },
  { name: '客户姓名', value: 'memberName' },
  { name: '客户手机号', value: 'memberMobile' },
  { name: '审核人', value: 'reviewUserName' },
  { name: '客服姓名', value: 'kfUserName' },
  { name: '商户名称', value: 'merchantName' }
]
export const searchTypeOrderRemark = [
  { name: '订单号', value: 'orderNo' },
  { name: '客户姓名', value: 'memberName' },
  { name: '跟进人', value: 'traceUserName' }
]
export const reviewStatus = [
  { name: '全部', value: '0' },
  { name: '待提审', value: '1' },
  { name: '审核中', value: '2' }
]
// 审核明细--搜索
export const search_type_review_assign = [
  { name: '订单号', value: 'orderNo' },
  { name: '客户名', value: 'memberName' },
  { name: '手机号', value: 'memberMobile' },
  { name: '审核', value: 'reviewUserName' },
  { name: '客服', value: 'kfUserName' }
]
// 规则配置
// 规则类型
export const dict_ruleType_search = [
  { name: '全部', value: '0' },
  { name: '前置规则', value: '1' },
  { name: '弱规则', value: '2' },
  { name: '强规则', value: '3' }
]
export const dict_ruleType = [
  { name: '前置规则', value: 1 },
  { name: '弱规则', value: 2 },
  { name: '强规则', value: 3 }
]
// 运算符
export const dict_operator = [
  { name: '大于等于', value: 'ge' },
  { name: '大于', value: 'gt' },
  { name: '等于', value: 'eq' },
  { name: '小于', value: 'lt' },
  { name: '小于等于', value: 'le' },
  { name: '介于', value: 'between' }
]
export const dict_in_operator = [
  { name: 'IN', value: 'in' },
  { name: 'IN & Start', value: 'inStartWith' }
]

// 订单管理相关
export const searchTypeOrder = [
  { name: '订单号', value: 'orderNo' },
  { name: '审核人', value: 'reviewUserName' },
  { name: '客户姓名', value: 'memberName' },
  { name: '客户手机号', value: 'memberMobile' },
  { name: '客服', value: 'kfUserName' },
  { name: '商户', value: 'merchantName' },
  { name: '渠道', value: 'channelName' },
  { name: '门店归属', value: 'storeBusinessUserName' },
]
export const searchTypeBill = [
  { name: '订单号', value: 'orderNo' },
  { name: '审核人', value: 'reviewUserName' },
  { name: '客户姓名', value: 'memberName' },
  { name: '客户手机号', value: 'memberMobile' },
  { name: '客服', value: 'kfUserName' },
  { name: '商户', value: 'merchantName' }
]
export const orderStatus = [
  { name: '全部', value: '0' },
  { name: '审核中', value: '1' },
  { name: '待支付', value: '2' },
  { name: '待签章', value: '3' },
  { name: '待发货', value: '4' },
  { name: '待收货', value: '5' },
  { name: '租赁中', value: '6' },
  { name: '逾期中', value: '7' },
  { name: '待买断', value: '8' },
  { name: '待归还', value: '9' },
  { name: '已完成', value: '91' },
  { name: '已取消', value: '92' },
  { name: '已拒绝', value: '93' }
]
export const orderStatus2 = [
  { name: '审核中', value: '1' },
  { name: '待支付', value: '2' },
  { name: '待签章', value: '3' },
  { name: '待发货', value: '4' },
  { name: '待收货', value: '5' },
  { name: '租赁中', value: '6' },
  { name: '逾期中', value: '7' },
  { name: '待买断', value: '8' },
  { name: '待归还', value: '9' },
  { name: '已完成', value: '91' },
  { name: '已取消', value: '92' },
  { name: '已拒绝', value: '93' }
]
export const orderStatus3 = [
  { name: '租赁中', value: '6' },
  { name: '逾期中', value: '7' },
  { name: '待买断', value: '8' },
  { name: '待归还', value: '9' },
  { name: '已完成', value: '91' },
  { name: '已取消', value: '92' },
  { name: '已拒绝', value: '93' }
]
export const billStatus = [
  { name: '全部', value: '0' },
  { name: '待支付', value: '1' },
  { name: '已支付', value: '2' },
  { name: '已逾期', value: '3' },
  { name: '已取消', value: '4' }
]
export const credit_is_checked = [
  { name: '是', value: 1 },
  { name: '否', value: 0 }
]
export const notary_is_checked = [
  { name: '是', value: 1 },
  { name: '否', value: 0 }
]
export const creditQueryStatus = [
  { name: '查询中', value: '10' },
  { name: '成功', value: '11' },
  { name: '失败', value: '12' }
]
export const notaryStatus = [
  { name: '公证中', value: '4' },
  { name: '成功', value: '5' },
  { name: '失败', value: '7' }
]
export const dict_order_type = [
  { name: '普通订单', value: '1' },
  { name: '门店订单', value: '2' },
  { name: '卡券订单', value: '3' }
]
// 逾期等级
export const dict_overdue_level = [
  { name: '正常（0-30天）', value: 1 },
  { name: '关注（31-60天）', value: 2 },
  { name: '次级（61-120天）', value: 3 },
  { name: '可疑（121天-180天）', value: 4 },
  { name: '损失（180天以上）', value: 5 }
]
// 售后管理
export const searchTypeAfterSaleRemark = [
  { name: '客户姓名', value: 'memberName' },
  { name: '跟进人', value: 'traceUserName' }
]
export const afterSaleStatus = [
  { name: '全部', value: '0' },
  { name: '待分配', value: '1' },
  { name: '跟进中', value: '2' }
]
export const afterSale_assign_search_type = [
  { name: '订单号', value: 'orderNo' },
  { name: '客户名', value: 'memberName' },
  { name: '手机号', value: 'memberMobile' },
  { name: '售后', value: 'afterSaleUserName' }
]
// 商户管理
// 提现列表
export const dict_merchant_withdraw_status = [
  { name: '已申请', value: 10 },
  { name: '提现中', value: 20 },
  { name: '提现成功', value: 21 },
  { name: '提现失败', value: 22 }
]
// 渠道管理
export const channelOrderStatus = [
  { name: '审核中', value: '1' },
  { name: '待支付', value: '2' },
  { name: '待签章', value: '3' },
  { name: '待发货', value: '4' },
  { name: '待收货', value: '5' },
  { name: '租赁中', value: '6' },
  { name: '逾期中', value: '7' },
  { name: '待买断', value: '8' },
  { name: '待归还', value: '9' },
  { name: '已完成', value: '91' },
  { name: '已取消', value: '92' },
  { name: '已拒绝', value: '93' }
]
export const channelSearchKw = [
  { name: '用户名', value: 'memberName' },
  { name: '手机号', value: 'memberMobile' },
  { name: '订单号', value: 'orderNo' },
  { name: '订单渠道', value: 'orderChannelName' }
]
export const memberType = [
  { name: '新客', value: '1' },
  { name: '在租', value: '2' }
]
export const isRegister = [
  { name: '已注册', value: '1' },
  { name: '新注册', value: '0' }
]
export const dict_device_status = [
  { name: '在线已插卡', value: 1 },
  { name: '在线未插卡', value: 2 },
  { name: '离线已插卡', value: 3 },
  { name: '离线未插卡', value: 4 },
]
// 地图

export const key = '234a9cbb7d973ebdd7f6a9464e42a830'
export const code = '271baeded7d4e6ebec7dd9305ce6003d'
// export const key = '3768813424129a70d14ab4eb3c2f9938'
// export const code = '5ff55006fe7c9254f90859397a308524'
