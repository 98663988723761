import { mapGetters } from 'vuex'
import AUTH_BTN from '@/enum/btn_auth'

export const authBtnMixin = {
  data() {
    return {
      AUTH_BTN,
      user_btns: []
    }
  },

  mounted() {},

  computed: {
    ...mapGetters(['isAdmin', 'authBtns'])
  },

  methods: {
    isShowBtn(btn_code) {
      if (this.isAdmin) {
        return 1
      }

      if (this.authBtns.indexOf(btn_code) > -1) {
        return 1
      }

      return 0
    }
  },

  directives: {
    checkbtn(element, binding) {
      if (!binding.value) {
        element.style.display = 'none'
      }
    }
  },

  provide() {
    return {
      context: this
    }
  }
}
